import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
function Help(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
  };

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    props.setOpen(false);
  };
  const handleCallClick = () => {
    window.location.href = `tel:${process.env.REACT_APP_HELP_NUMBER}`;
  };
  return (
    <>
      <Modal
        open={props.open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Help_modal_wrapper">
            <div className="Modal_Header d_flex_between">
              <h3> Help </h3>
              <button onClick={handleClose}> Close[x] </button>
            </div>
            <div className="Modal_body d_flex_between">
              <div onClick={handleCallClick} className="d_flex_vertical">
                <CallOutlinedIcon />
                <p>{process.env.REACT_APP_HELP_NUMBER} </p>
              </div>
              <div className="d_flex_vertical">
                <a className="support__mail" href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>
                  <EmailOutlinedIcon />
                  {process.env.REACT_APP_SUPPORT_MAIL}
                </a>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Help;
