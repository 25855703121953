import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import Congratulations from "./Congratulations";
import { getOrderDetails, getdeliverytime,getGeofenceByFleetId } from "../API";
import {findNorthWestAndSouthEast} from "../utils"

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function OrderStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [orderDetails, setorderDetails] = useState(null);
  const [etaDetails, setETADetails] = useState(null);
  const [etaLoader, setETALoader] = useState(true);
  const { orderID } = useParams();
  const [otp, setOtp] = useState(orderID?.substr(-4));
  const history = useNavigate();
  const [maxBoundCordinates,setBoundCordinates] = useState({})
  useEffect(() => {
    const intervalId = setInterval(() => {
      getOrderDetails(orderID)
        .then((res) => {
          console.log(res?.data.data," RES")
          getGeofenceByFleetId(res?.data?.data?.fleetId).then((res)=>{
          setBoundCordinates(findNorthWestAndSouthEast(res.data.geofence))
          console.log(maxBoundCordinates)          })
          localStorage.setItem("name", res?.data?.data?.name);
          localStorage.setItem("email", res?.data?.data?.email);
          localStorage.setItem("phone", res?.data?.data?.phone);
          localStorage.setItem("pin", res?.data?.data?.pin);
          if (res.data?.data?.statusCode === 9) {
            history(`/orderdelivered/${orderID}`);
          }
          if ([10, 14].includes(res.data?.data.statusCode)) {
            history(`/ordercancel/${orderID}`);
          }
          setorderDetails(res.data.data);
          setOtp(orderID.substr(-4));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(true);
          console.log("Error ", err);
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      getdeliverytime(orderID)
        .then((res) => {
          setETADetails(res?.data?.result);
          setETALoader(false);
        })
        .catch((err) => {
          setETALoader(true);
          console.log("Error ", err);
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div>
      {/* {false ? (
        <Loader />
      ) : ( */}
      <Congratulations
        maxBoundCordinates = {maxBoundCordinates}
        orderDetails={orderDetails}
        otp={otp}
        eta={etaDetails}
        etaLoader={etaLoader}
        isLoading={isLoading}
      />
      {/* )} */}
    </div>
  );
}

export default OrderStatus;
