import React from "react";
import Help from "../modals/Help";

function Footer(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const loaderStyle = {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderTop: '4px solid #000',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  };

  const keyframesAnimation = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <>
      {props.orderdelivered ? (
        <div className="fixed z-20 flex flex-col gap-2">
          <div className="fixed flex bottom-[0rem] items-center justify-around w-full rounded-md h-16 px-3 bg-ottonomyBlue ">
            <button
              className="!px-8 !pb-[0.25rem] !pt-[0.25rem] font-bold bg-white rounded-lg"
              onClick={handleOpen}
            >
              <p className="text-ottonomyBlue">Help</p>
            </button>
          </div>
          {/* <div className="fixed flex bottom-0 items-center w-full h-[5rem] px-4 py-4 text-white">
            <button className="w-full h-10 !bg-[#02B7D4] font-bold text-base rounded-md">
              <a
                href="https://ck85l93ctgh.typeform.com/to/BBvgB1HV"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center w-full h-full px-4 text-base text-white no-underline max-sm:text-sm"
              >
                Please let us know about your experience
              </a>
            </button>
          </div> */}
        </div>
      ) : (
        <div className="fixed bottom-[0rem] z-20 flex items-center justify-around w-full h-24 px-3 bg-ottonomyBlue ">
          <div className="flex flex-col font-bold text-left text-white">
            <p className="text-base">Estimated Delivery Time</p>
            {props.etaLoader ? (
              <div className="self-center">
                <style>{keyframesAnimation}</style>
                <div style={loaderStyle}></div>
              </div>
            ) : (
              <p className="text-xl">
                {props?.eta > 0 ? props?.eta + " mins" : "reached"}{" "}
              </p>
            )}
          </div>
          <div className="bg-white h-full w-[0.5px]" />
          <button
            className="px-4 py-3 font-bold bg-white rounded-lg"
            onClick={handleOpen}
          >
            <p className="text-ottonomyBlue">Help</p>
          </button>
        </div>
      )}

      <Help open={open} setOpen={setOpen} handleOpen={handleOpen} />
    </>
  );
}

export default Footer;
